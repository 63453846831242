import { ApolloClient, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { isEqual, merge } from 'lodash';
import { useMemo } from 'react';
import { cache, errorCode, resolvers, typeDefs } from './cache';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;

export function initializeApollo(initialState = null, apiUrl) {
  const _apolloClient = apolloClient ?? createClient({ apiUrl });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function createClient({ logError, apiUrl } = {}) {
  const errorLink = onError(({ networkError }) => {
    if (networkError && networkError.statusCode) {
      const err = new Error('Apollo client network error');
      err.code = 'APOLLO_NETWORK_ERROR';
      err.metadata = {
        statusCode: networkError.statusCode,
        payload: networkError.bodyText,
      };
      /**
       * TODO: this is temporarily disabled due to excessive logging to sentry
       * enable again when userMe no longer returns 401
       */
      // logError(err);

      errorCode(networkError.statusCode);
    }
  });

  const httpLink = new HttpLink({
    uri: `${apiUrl}/graphql`,
    credentials: 'include',
  });

  return new ApolloClient({
    cache,
    typeDefs,
    resolvers,
    ssrMode: typeof window === 'undefined',
    link: errorLink.concat(httpLink),
    connectToDevTools: process.env.NODE_ENV === 'development',
  });
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps = {}) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];

  const apiUrl = pageProps.apiUrl;

  const store = useMemo(() => {
    if (!apiUrl) return;
    return initializeApollo(state, apiUrl);
  }, [state, apiUrl]);

  return store;
}
