import type { Context } from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

type Config = {
  googleApiKey: string;
  releaseStage: string;
  sentryDsn: string;
  supportEmail: string;
  econsentPreviewUrl: string;
  supportUrl: string;
  version: string;
  systemVersion: string;
  videoUrl: string;
  uploadUrl: string;
  apiUrl: string;
  osaApi: string;
  osaLocales: string;
  freshDesk: string;
  freshDeskAdmin: string;
  mixpanelToken: string;
};

const ConfigContext: Context<Config> = createContext(undefined);
ConfigContext.displayName = 'ConfigContext';

function ConfigProvider(props) {
  const [config, setConfig] = useState({});
  const isFirstMount = useRef<boolean>(true);

  useEffect(() => {
    if (!isFirstMount.current) {
      return;
    }

    isFirstMount.current = false;

    fetch('/api/config')
      .then(res => res.json())
      .then(config => setConfig(config));
  }, []);

  return <ConfigContext.Provider value={config} {...props} />;
}

function MockConfigProvider(props) {
  return <ConfigContext.Provider value={{}} {...props} />;
}

function useConfig() {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error(`useConfig must be used within a ConfigProvider`);
  }

  return context;
}

export { ConfigProvider, MockConfigProvider, useConfig };
