import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { CANDIDATES_ACCORDION_KEY } from '../../constants';
import { checkAccess } from './checkAccess';
import { createAccountMethods } from './createAccountMethods';
import { useAutoAssignProxyNumber } from './useAutoAssignProxyNumber';
import { useFeatures } from './useFeatures';
import { useLogout } from './useLogout';

/**
 * @typedef {DISABLED | DEVICE | EMAIL | SMS} TYPE_2FA
 *
 * @typedef {object} User
 * @property {string} id
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {boolean} hasEnabled2FA
 * @property {string} currentCountry
 * @property {TYPE_2FA} type2fa
 *
 * @typedef {object} AuthObject
 * @property {boolean} loading
 * @property {boolean} isLoggedIn
 * @property {User} user
 * @property {object} settings
 * @property {function} login
 * @property {function} logout
 * @property {function} update
 * @property {function} updateUserSettings
 */

/** @type {import('react').Context<AuthObject>} */
const AuthContext = createContext(undefined);
AuthContext.displayName = 'AuthContext';

const AUTH_QUERY = gql`
  query GetVerifiedSession {
    userMe {
      id
      firstName
      lastName
      email
      type2fa
      hasEnabled2FA
      currentCountry

      userSetup {
        userId
        key
        value
      }

      learning {
        userId
        consented
      }

      permissions {
        statusesByDomain
        sitesByStudy
        actions
      }

      roles {
        nodes {
          role {
            id
            name
          }
        }
      }
    }
  }
`;

const CLEAR_LAST_ERROR = gql`
  mutation {
    clearLastError @client
  }
`;

//TODO: return a User to auto update graph
const setUserSetup = gql`
  mutation WEB_setUserProfileSettings(
    $userId: ID!
    $key: UserSetupKey!
    $value: JSONObject!
  ) {
    userSetup(input: { userId: $userId, key: $key, value: $value }) {
      userId
      key
      value
    }
  }
`;

export function AuthProvider(props) {
  const [
    getAccess,
    { data, loading, refetch, called },
  ] = useLazyQuery(AUTH_QUERY, { fetchPolicy: 'no-cache' });
  const [clearLastError] = useMutation(CLEAR_LAST_ERROR);
  const [setUserProfileSettings] = useMutation(setUserSetup);
  const { autoAssignProxyNumberIfNeeded } = useAutoAssignProxyNumber();

  useEffect(() => {
    getAccess();
  }, []);

  const logout = useLogout();

  const value = useMemo(() => {
    const methods = createAccountMethods(props.apiUrl);
    const user = data?.userMe;

    const settingsFromUser = user =>
      Object.fromEntries((user?.userSetup ?? []).map(x => [x.key, x]));

    const makeUpdateUserSettings = userId => ({ key, value }) =>
      setUserProfileSettings({
        variables: { userId, key, value },
      }).then(refetch);

    return {
      loading: loading || !called,
      isLoggedIn: !!data,
      user,
      settings: settingsFromUser(user),

      ...methods,

      login: (email, password, token) =>
        methods
          .createLogin(email, password, token)
          .then(() => clearLastError())
          .then(async () => {
            const { data } = await getAccess();
            const user = data?.userMe;
            const userId = user?.id;
            const settings = settingsFromUser(user);
            autoAssignProxyNumberIfNeeded(
              user,
              settings,
              makeUpdateUserSettings(userId),
            );

            return data;
          }),

      logout: () => {
        window.localStorage.setItem(CANDIDATES_ACCORDION_KEY, false);
        return logout();
      },

      update: () => refetch(),

      updateUserSettings: makeUpdateUserSettings(user?.id),

      permissions: user?.permissions,
      actions: user?.permissions?.flatMap(obj => obj.actions),
      roles: user?.roles?.nodes.map(({ role }) => role.name) ?? [],
    };
  }, [
    loading,
    called,
    data,
    getAccess,
    clearLastError,
    logout,
    refetch,
    setUserProfileSettings,
    props.apiUrl,
  ]);

  return <AuthContext.Provider value={value} {...props} />;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}

function useAccess() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAccess must be used within a AccessProvider`);
  }
  return context;
}

export function useCheckAccess(studyId) {
  const access = useAccess();
  const { features, loading } = useFeatures({ studyId });

  const checkAccessHandler = useCallback(
    requirements => checkAccess(access, requirements, features),
    [access, features],
  );

  return { checkAccess: checkAccessHandler, loading };
}

export function MockAccessProvider(props) {
  return <AuthContext.Provider {...props} />;
}
