import { debounce } from 'lodash';

/**
 * debounces an async function in a way that can be awaited.
 * By default debounce doesn't return a promise for async functions unless it's invoking them immediately
 *
 * @param func async function to debounce calls for.
 * @param {number} [wait=0] same function as lodash.debounce's wait parameter. Call this function at most this often.
 * @param {Object} [options]
 *
 * @returns a promise which will be resolved/ rejected only if the function is executed, with the result of the underlying call.
 */
export function asyncDebounce(func, wait, options) {
  const debounced = debounce(
    (resolve, reject, args) => {
      func(...args)
        .then(resolve)
        .catch(reject);
    },
    wait,
    options,
  );

  return (...args) =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    });
}
