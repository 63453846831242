import { sample } from '@lib/fp';
import fullScreenPageSvg from 'public/assets/winter_background_blue_stars.svg';
import { createHoneyTheme } from './createHoneyTheme';
import makeFullScreenLogo from './makeFullScreenLogo';
import makeNavCollapsedLogo from './makeNavCollapsedLogo';
import makeNavExpandedLogo from './makeNavExpandedLogo';

const svgToBase64 = svgString =>
  `data:image/svg+xml;base64,${Buffer.from(svgString).toString('base64')}`;

const logoColors = sample([
  { hat: 'fcfcfc', bobble: 'fff', pattern: 'd30000' }, // White whith red pattern
  { hat: 'E1AD01', bobble: 'E1AD01', pattern: 'fff' }, // Mustard yellow hat, white pattern
  { hat: '117ba5', bobble: 'f4aa27', pattern: 'fff' }, // Blue hat, mustard bobble, white pattern
  { hat: '117ba5', bobble: 'fff', pattern: 'fff' }, // Blue hat, white bobble, white pattern
  { hat: '556b2f', bobble: 'f2b800', pattern: 'fff' }, // Olive hat, mustard bobble, white pattern
  { hat: 'ffdf00', bobble: 'fcfcfc', pattern: '396afa' }, // Gold yellow hat, grey bobble, grey pattern
  { hat: '903ab5', bobble: 'fff', pattern: 'fff' }, // Purple hat, white bobble, white pattern
  { hat: 'c00', bobble: 'f2b800', pattern: 'e3f9ff' }, // Red hat, mustard bobble, white-ish pattern
]);

const randomChristmasTheme = createHoneyTheme({
  logo: {
    fullScreenPage: svgToBase64(makeFullScreenLogo(logoColors)),
    navigationCollapsed: svgToBase64(makeNavCollapsedLogo(logoColors)),
    navigationExpanded: svgToBase64(makeNavExpandedLogo(logoColors)),
  },
  background: {
    standard: '#EEF5F3',
    navigation: 'linear-gradient(180deg, #5D0202 55.52%, #D10000 100%)',
    fullScreenPage: `url("${fullScreenPageSvg.src}") bottom left / cover no-repeat`,
  },
  palette: {
    secondary: {
      main: 'hsl(147, 100%, 30%)',
      light: 'hsl(147, 71%, 43%)',
      dark: 'hsl(147, 100%, 20%)',
    },
    primary: {
      main: 'hsl(147, 74%, 23%)',
      light: 'hsl(147, 60%, 37%)',
      dark: 'hsl(147, 85%, 18%)',
    },
  },
});

export default randomChristmasTheme;
