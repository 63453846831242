/**
 * create a distinct array from array of primitives
 *
 * @param {*[]} xs
 * @returns {*[]}
 */

export function distinctArray(xs) {
  return Array.from(new Set(xs).values());
}
