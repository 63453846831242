import { gql, InMemoryCache, makeVar } from '@apollo/client';

export const errorCode = makeVar(null);

export const typeDefs = gql`
  type Mutation {
    logout: null
    lastError(statusCode: Int): Int
    clearLastError: null
  }
  type Query {
    lastError: Int
  }
`;

export const resolvers = {
  Mutation: {
    logout(_, args, ctx) {
      ctx.client.stop();
      return ctx.client.clearStore();
    },
    lastError(_, { statusCode }) {
      return errorCode(statusCode);
    },
    clearLastError() {
      return errorCode(null);
    },
  },
};

/**
 * @returns {InMemoryCache}
 */
export const cache = new InMemoryCache({
  typePolicies: {
    AggregateFunctions: {
      keyFields: false,
    },
    AnalyticsCandidates: {
      keyFields: false,
    },
    Candidate: {
      fields: {
        dateOfBirth: {
          merge: true,
        },
        externalIds: {
          merge(existing = [], incoming, { readField }) {
            const externalIdsBySource = {};
            [...existing, ...incoming].forEach(externalId => {
              externalIdsBySource[readField('source', externalId)] = externalId;
            });
            return Object.values(externalIdsBySource);
          },
        },
      },
    },
    ConfigurationEntry: {
      keyFields: ['name'],
    },
    Country: {
      keyFields: ['code'],
    },
    FeatureOptions: {
      keyFields: ['studyId'],
    },
    Learning: {
      keyFields: ['userId'],
    },
    Number: {
      keyFields: ['value'],
    },
    ExternalId: {
      keyFields: ['candidateId', 'source'],
    },
    UserSetup: {
      keyFields: ['userId', 'key'],
    },
    Query: {
      fields: {
        lastError: {
          read() {
            return errorCode();
          },
        },
      },
    },
  },
});
