import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import MuiSnackbar from '@mui/material/Snackbar';
import makeStyles from '@mui/styles/makeStyles';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  success: { backgroundColor: theme.palette.primary.dark },
  error: { backgroundColor: theme.palette.error.dark },
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UndoBtn = props => (
  <Box mr={3}>
    <Button color="inherit" size="small" {...props} variant="outlined">
      <FormattedMessage defaultMessage="Undo" />
    </Button>
  </Box>
);

const CloseBtn = props => (
  <IconButton size="small" aria-label="close" color="inherit" {...props}>
    <CloseIcon fontSize="small" />
  </IconButton>
);

const Snackbar = ({
  open,
  onClose,
  severity,
  action,
  onAction,
  message,
  autoHideDuration = 15,
}) => {
  const classes = useStyles();

  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={autoHideDuration * 1e3}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ py: '12px' }}
    >
      <Alert
        icon={false}
        onClose={onClose}
        className={classes[severity] ?? classes.success}
        action={
          <>
            {action && <UndoBtn onClick={onAction} />}
            <CloseBtn onClick={onClose} />
          </>
        }
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
};
export default Snackbar;
