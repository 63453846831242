import languages from '@cospired/i18n-iso-languages';
import countries from 'i18n-iso-countries';

const registerLanguages = locale => {
  let myLocale;
  let translationFile;
  try {
    myLocale = locale.toLocaleLowerCase().substring(0, 2);
    translationFile = require(`@cospired/i18n-iso-languages/langs/${myLocale}.json`);
  } catch (_) {
    // Fallback to english
    myLocale = 'en';
    translationFile = require('@cospired/i18n-iso-languages/langs/en.json');
  }
  if (translationFile) languages.registerLocale(translationFile);
  return myLocale;
};

const registerCountries = locale => {
  let myLocale;
  let translationFile;
  try {
    myLocale = locale.toLocaleLowerCase().substring(0, 2);
    translationFile = require(`i18n-iso-countries/langs/${myLocale}.json`);
  } catch (_) {
    // Fallback to english
    myLocale = 'en';
    translationFile = require('i18n-iso-countries/langs/en.json');
  }
  if (translationFile) countries.registerLocale(translationFile);
  return myLocale;
};

export const registerTranslations = ({ locale }) => {
  registerLanguages(locale);
  registerCountries(locale);
};

function loadLocaleData(locale) {
  switch (locale) {
    default:
      return require('../compiled-lang/en.json');
  }
}

export const getMessages = ({ locale, defaultLocale }) => {
  // If the default locale is being used we can skip it
  if (locale === defaultLocale) {
    return {};
  }

  return loadLocaleData(locale);
};

export const getLanguage = () =>
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en';
