import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { first, isEmpty, isNil } from '../fp';

const useStyles = makeStyles(theme => ({
  content: {
    margin: 0,
    padding: theme.spacing(0, 3),
    overflowY: 'initial',
    color: theme.palette.text.primary,
    ...theme.typography.body1,
  },
  actions: {
    margin: 0,
    padding: theme.spacing(6, 3, 3, 3),
    justifyContent: 'flex-end',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: theme.palette.grey[500],
    padding: 0,
  },
  paperRoot: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 10,
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
}));

const ConfirmationDialog = props => {
  if (props.variant === 'destructive') {
    return <Destructive {...props} />;
  }

  return <Info {...props} />;
};

const Info = ({
  open,
  title,
  message,
  onConfirm,
  onDismiss,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  blurBackground,
  confirmOnClose = false,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      PaperProps={{ classes: { root: classes.paperRoot } }}
      BackdropProps={{
        classes: {
          root: blurBackground ? classes.backDrop : null,
        },
      }}
    >
      <DialogTitle sx={{ typography: 'subtitle1', m: 0, p: 3 }}>
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={confirmOnClose ? onConfirm : onDismiss}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content}>{message}</DialogContent>

      <DialogActions className={classes.actions}>
        <Box mr={2}>
          <Button color="primary" variant="outlined" onClick={onDismiss}>
            {cancelText}
          </Button>
        </Box>
        <Box>
          <Button color="primary" variant="contained" onClick={onConfirm}>
            {confirmText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const Destructive = ({
  open,
  title,
  message,
  onConfirm,
  onDismiss,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  confirmationText,
  confirmationTextPlaceholder,
  confirmOnClose = false,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [confirmationInput, setConfirmationInput] = useState('');

  const handleDismiss = useCallback(() => {
    setConfirmationInput('');
    onDismiss();
  }, [onDismiss]);

  const handleConfirm = useCallback(() => {
    setConfirmationInput('');
    onConfirm();
  }, [onConfirm]);

  const confirmationTextAlternatives = useMemo(
    () =>
      isNil(confirmationText)
        ? []
        : Array.isArray(confirmationText)
        ? confirmationText
        : [confirmationText],
    [confirmationText],
  );

  const enableConfirmButton = useMemo(
    () =>
      isEmpty(confirmationTextAlternatives) ||
      confirmationTextAlternatives.some(
        alternative =>
          alternative.trim().toLowerCase() ===
          confirmationInput.trim().toLowerCase(),
      ),
    [confirmationInput, confirmationTextAlternatives],
  );

  return (
    <Dialog open={open} PaperProps={{ classes: { root: classes.paperRoot } }}>
      <DialogTitle sx={{ typography: 'subtitle1', m: 0, p: 3 }}>
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={confirmOnClose ? onConfirm : onDismiss}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Stack spacing={4}>
          <Stack direction="row" spacing={2}>
            <ErrorIcon
              sx={{
                fontSize: 40,
                color: ({ palette }) => palette.error.main,
              }}
            />
            <DialogContentText color="textPrimary">{message}</DialogContentText>
          </Stack>
          {confirmationText && (
            <TextField
              label={
                confirmationTextPlaceholder ||
                intl.formatMessage(
                  {
                    defaultMessage:
                      'Type “{confirmationText}” to confirm this action',
                  },
                  { confirmationText: first(confirmationTextAlternatives) },
                )
              }
              value={confirmationInput}
              onChange={e => setConfirmationInput(e.target.value)}
              sx={{ backgroundColor: ({ palette }) => palette.grey[50] }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions disableSpacing className={classes.actions}>
        <Box mr={3}>
          <Button color="primary" variant="outlined" onClick={handleDismiss}>
            {cancelText}
          </Button>
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            disabled={!enableConfirmButton}
            onClick={handleConfirm}
            sx={{
              backgroundColor: ({ palette }) => palette.error.main,
              '&:hover': {
                backgroundColor: ({ palette }) => palette.error.dark,
              },
            }}
          >
            {confirmText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
