/**
 * @typedef {Object} CheckCodeResult
 * @property {string} value    - The recipient e-mail or phone number which was checked (should be used on client side for security)
 * @property {boolean} valid   - false when code invalid or expired
 * @property {boolean} expired - true when code valid but expired
 */

/**
 * @typedef {Object} UserDataInput
 * @property {string} token     - The token id
 * @property {object} userData  - Provided user data (name, e-mail, password, 2FA etc.)
 */

export function createAccountMethods(apiUrl) {
  return {
    /**
     * Create a session with the gateway (external api).
     *
     * @async
     * @param {string} email
     * @param {string} password
     * @param {string} token
     * @throws {Error} - when username and password do not match.
     */
    createLogin: (email, password, token = '') =>
      fetch(`${apiUrl}/user_sessions`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${btoa(
            `${encodeURIComponent(email)}:${encodeURIComponent(password)}`, // encodeURIComponent allows for proper handling of special characters like "ß"
          )}`,
        },
        body: `totpToken=${token.replace(/\s/g, '')}`,
      }).then(response => {
        if (response.status !== 201) {
          return response.json().then(data => {
            return Promise.reject(data);
          });
        }
        return response;
      }),

    /**
     * Create a request for password reset
     *
     * @param {object} data
     * @param {string} data.value - The recipient e-mail or phone number
     */
    resetPassword: data =>
      fetch(`${apiUrl}/user/password/resetRequest`, createBody(data)),

    /**
     * Set new password
     *
     * @param {object} data
     * @param {string} data.token     - The token id
     * @param {string} data.password  - The new password
     */
    setNewPassword: data =>
      fetch(`${apiUrl}/user/password/setNew`, createBody(data)),

    /**
     * Update user data persisted in token (as token data)
     *
     * @param {string} token     - The token id
     * @param {object} userData  - Provided user data (name, e-mail, phone number, selected 2FA type)
     */
    updateUserData: (token, userData) =>
      fetch(`${apiUrl}/user/invite`, createBody({ token, userData }, 'PUT')),

    /**
     * Generate new code and send it to `value`
     *
     * @param {object} data
     * @param {string} data.token - The token id
     * @param {string} data.value - The recipient e-mail or phone number
     */
    sendCode: data => fetch(`${apiUrl}/user/invite/sendCode`, createBody(data)),

    /**
     * Check the code and respond valid/invalid/expired
     *
     * @param {object} data
     * @param {string} data.token - The token id
     * @param {string} data.value - The recipient e-mail or phone number
     * @param {string} data.code  - The code to be checked (provided by user)
     * @returns {CheckCodeResult}
     */
    checkCode: ({ code, ...rest }) =>
      fetch(
        `${apiUrl}/user/invite/checkCode`,
        createBody({ code: code.replace(/[^\d]/gi, ''), ...rest }),
      ).then(res => res.json()),

    /**
     * Verify user account setup before it's being created.
     *
     * @param {UserDataInput} data
     */
    validate: data =>
      fetch(`${apiUrl}/user/invite/validate`, createBody(data)).then(res =>
        res.json(),
      ),

    /**
     * Create new user account
     *
     * @param {UserDataInput} data
     */
    createAccount: data =>
      fetch(`${apiUrl}/user/invite/createAccount`, createBody(data)),

    /**
     * Unsubscribe the user
     *
     * @param {Object} payload
     * @param {String} userId
     * @param {String} action
     * @param {String} key
     */
    unsubscribe: payload =>
      fetch(`${apiUrl}/user_unsubscribe`, createBody(payload)),

    getConsentContent: () =>
      fetch(`${apiUrl}/user/consent`, createBody(null, 'GET')),
  };
}

const createBody = (data, method = 'POST') => ({
  method,
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: data ? JSON.stringify(data) : undefined,
});
