import * as React from 'react';
import Snackbar from './Snackbar';

const SnackbarContext = React.createContext(undefined);

const SnackbarProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState();

  const openDialog = ({ severity, message, action, autoHideDuration }) => {
    setDialogOpen(true);
    setDialogConfig({
      severity,
      message,
      action,
      autoHideDuration,
    });
  };

  const resetDialog = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDialogOpen(false);
  };

  const onAction = () => {
    resetDialog();
    dialogConfig?.action();
  };

  return (
    <SnackbarContext.Provider value={{ openDialog }}>
      <Snackbar
        open={dialogOpen}
        onClose={resetDialog}
        severity={dialogConfig?.severity}
        action={dialogConfig?.action}
        onAction={onAction}
        message={dialogConfig?.message}
        autoHideDuration={dialogConfig?.autoHideDuration}
      />
      {children}
    </SnackbarContext.Provider>
  );
};

const useSnackbar = () => {
  const context = React.useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }

  const openSnackbar = ({ ...options }) =>
    new Promise(res => {
      context.openDialog({ actionCallback: res, ...options });
    });

  return { openSnackbar };
};

export { SnackbarProvider, useSnackbar };
