import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { logError } from '../logging';

const LOGOUT_CLIENT = gql`
  mutation {
    logout @client
  }
`;

const LOGOUT_BACKEND = gql`
  mutation {
    logout
  }
`;

export function useLogout() {
  const [logout1] = useMutation(LOGOUT_CLIENT);
  const [logout2] = useMutation(LOGOUT_BACKEND);

  return useCallback(
    async () => Promise.all([logout1(), logout2()]).catch(e => logError(e)),
    [logout1, logout2],
  );
}
