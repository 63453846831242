import { useConfig } from '@lib/config';
import mixpanel from 'mixpanel-browser';
import type { Context } from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

type TrackingState = {
  isConfigReceived: boolean;
  isEnabled: boolean;
  isInitialized: boolean;
};

const createInitialValue = (): TrackingState => ({
  isConfigReceived: false,
  isInitialized: false,
  isEnabled: false,
});

const TrackingStateContext: Context<TrackingState> = createContext(undefined);

export const TrackingProvider = props => {
  const { mixpanelToken, apiUrl } = useConfig();
  const [trackingState, setTrackingState] = useState(createInitialValue());
  const isConfigReceived = Boolean(apiUrl);
  const isTrackingEnabled = Boolean(mixpanelToken);

  useEffect(() => {
    if (trackingState.isConfigReceived !== isConfigReceived) {
      setTrackingState({ ...trackingState, isConfigReceived });
    }
  }, [isConfigReceived, trackingState]);

  useEffect(() => {
    if (trackingState.isEnabled !== isTrackingEnabled) {
      setTrackingState({ ...trackingState, isEnabled: isTrackingEnabled });
    }
  }, [isTrackingEnabled, trackingState]);

  useEffect(() => {
    if (trackingState.isEnabled && !trackingState.isInitialized) {
      mixpanel.init(mixpanelToken);
      setTrackingState({ ...trackingState, isInitialized: true });
    }
  }, [mixpanelToken, trackingState.isInitialized, trackingState]);

  return <TrackingStateContext.Provider value={trackingState} {...props} />;
};

export const MockTrackingProvider = props => {
  return (
    <TrackingStateContext.Provider value={createInitialValue()} {...props} />
  );
};

type TrackingOperation = (shouldTrack: boolean) => void;

export const useTracking = () => {
  const trackingState: TrackingState = useContext(TrackingStateContext);
  const { isConfigReceived, isEnabled, isInitialized } = trackingState;

  const operationQueueRef = useRef<TrackingOperation[]>([]);

  const shouldTrack = (trackingState: TrackingState): boolean =>
    trackingState.isEnabled && trackingState.isInitialized;

  const setupCompleted = isInitialized || (isConfigReceived && !isEnabled);

  useEffect(() => {
    if (setupCompleted && Boolean(operationQueueRef.current?.length)) {
      const operations = operationQueueRef.current;
      operationQueueRef.current = [];
      operations.forEach(operation => operation(shouldTrack(trackingState)));
    }
  }, [setupCompleted, trackingState]);

  // Helper method to make sure that tracking events run after the config
  // is received and, if enabled, Mixpanel has been initialized.
  const afterSetup = (operation: TrackingOperation) => {
    if (setupCompleted) {
      operation(shouldTrack(trackingState));
    } else {
      operationQueueRef.current.push(operation);
    }
  };

  const userMetrics = {
    identify: id => {
      afterSetup(shouldTrack => {
        if (shouldTrack) {
          mixpanel.identify(id);
        } else {
          console.log('Mixpanel - identify:', id);
        }
      });
    },
    alias: id => {
      afterSetup(shouldTrack => {
        if (shouldTrack) {
          mixpanel.alias(id);
        } else {
          console.log('Mixpanel - alias:', id);
        }
      });
    },
    track: (name, props) => {
      afterSetup(shouldTrack => {
        if (shouldTrack) {
          mixpanel.track(name, props);
        } else {
          console.log('Mixpanel - track:', name, props);
        }
      });
    },
    people: {
      set: props => {
        afterSetup(shouldTrack => {
          if (shouldTrack) {
            mixpanel.people.set(props);
          } else {
            console.log('Mixpanel - people:', props);
          }
        });
      },
    },
    register: props => {
      afterSetup(shouldTrack => {
        if (shouldTrack) {
          mixpanel.register(props);
        } else {
          console.log('Mixpanel - register:', props);
        }
      });
    },
    register_once: props => {
      afterSetup(shouldTrack => {
        if (shouldTrack) {
          mixpanel.register_once(props);
        } else {
          console.log('Mixpanel - register once:', props);
        }
      });
    },
    track_links: (query, name, props = undefined) => {
      if (!setupCompleted) {
        return;
      }

      const elements = window.document.querySelectorAll(query);
      if (shouldTrack(trackingState)) {
        elements.forEach(element => {
          if (!element.isTracked) {
            mixpanel.track_links(element, name, props);
            element.isTracked = true;
          }
        });
      } else {
        elements.forEach(element => {
          if (!element.hasDebugTrackLinksListener) {
            element.addEventListener('click', () => {
              console.log('Mixpanel - track links:', query, name, props);
            });
            element.hasDebugTrackLinksListener = true;
          }
        });
      }
    },
  };

  return userMetrics;
};
