import { useConfig } from '@lib/config';
import { ThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import christmasTheme from 'src/theme/christmasTheme';
import randomChristmasTheme from 'src/theme/randomChristmasTheme';
import defaultTheme from 'src/theme/theme';

const DEFAULT_THEME = 'default';

const HoneyThemeProvider = ({ children }) => {
  const { theme: themeConfig } = useConfig();

  const themeKey = useMemo(() => {
    const [theme, epochStart, epochEnd] = (themeConfig ?? '')
      .split(':')
      .filter(Boolean);

    if (theme && isIntegerString(epochStart) && isIntegerString(epochEnd)) {
      const now = new Date();
      const start = new Date(Number(epochStart) * 1000);
      const end = new Date(Number(epochEnd) * 1000);

      if (start < now && now < end) {
        return theme;
      } else {
        return DEFAULT_THEME;
      }
    }

    if (theme && (!epochStart || !epochEnd)) {
      return theme;
    }

    return DEFAULT_THEME;
  }, [themeConfig]);

  return <ThemeProvider theme={themeMap[themeKey]}>{children}</ThemeProvider>;
};

const themeMap = {
  [DEFAULT_THEME]: defaultTheme,
  christmas: christmasTheme,
  randomChristmas: randomChristmasTheme,
};

const isIntegerString = arg => typeof arg === 'string' && /^\d+$/.test(arg);

export default HoneyThemeProvider;
