/**
 * Immutably swap items at given array indexes
 *
 * @param {any[]} items
 * @param {number} firstIndex
 * @param {number} secondIndex
 *
 * @returns {any[]}
 */
export function swapItems(items, firstIndex, secondIndex) {
  // Constant reference - we can still modify the array itself
  const results = items.slice();

  const firstItem = items[firstIndex];
  results[firstIndex] = items[secondIndex];
  results[secondIndex] = firstItem;

  return results;
}
