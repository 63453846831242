/**
 * @param {?object} object
 * @param {function} callback
 * @param {*} context
 * @return {?object}
 */

export function filterObject(object, callback, context) {
  if (!object) {
    return null;
  }

  let result = {};
  for (let name in object) {
    if (
      hasOwnProperty.call(object, name) &&
      callback.call(context, object[name], name, object)
    ) {
      result[name] = object[name];
    }
  }

  return result;
}
