import * as React from 'react';
import AlertDialog from './AlertDialog';
import ConfirmationDialog from './ConfirmationDialog';

const ConfirmationDialogContext = React.createContext(undefined);

const DialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState();

  const openConfirmation = ({
    variant,
    title,
    message,
    actionCallback,
    confirmText,
    cancelText,
    blurBackground,
    confirmationText,
    confirmationTextPlaceholder,
    confirmOnClose,
  }) => {
    setDialogOpen(true);
    setDialogConfig({
      variant,
      title,
      message,
      actionCallback,
      confirmText,
      cancelText,
      blurBackground,
      confirmationText,
      confirmationTextPlaceholder,
      confirmOnClose,
    });
  };

  const openAlert = ({
    variant,
    title,
    message,
    actionCallback,
    confirmText,
    cancelText,
    blurBackground,
    scrollContent,
  }) => {
    setAlertOpen(true);
    setDialogConfig({
      variant,
      title,
      message,
      actionCallback,
      confirmText,
      cancelText,
      blurBackground,
      scrollContent,
    });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setAlertOpen(false);
  };

  const onConfirm = (e, reason) => {
    if (['backdropClick'].includes(reason)) return;

    resetDialog();
    dialogConfig?.actionCallback(true);
  };

  const onDismiss = (e, reason) => {
    if (['backdropClick'].includes(reason)) return;

    resetDialog();
    dialogConfig?.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider
      value={{ openConfirmation, openAlert, resetDialog }}
    >
      <ConfirmationDialog
        open={dialogOpen}
        variant={dialogConfig?.variant}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        confirmText={dialogConfig?.confirmText}
        cancelText={dialogConfig?.cancelText}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        blurBackground={dialogConfig?.blurBackground}
        confirmationText={dialogConfig?.confirmationText}
        confirmationTextPlaceholder={dialogConfig?.confirmationTextPlaceholder}
        confirmOnClose={dialogConfig?.confirmOnClose}
      />
      <AlertDialog
        open={alertOpen}
        variant={dialogConfig?.variant}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        confirmText={dialogConfig?.confirmText}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        blurBackground={dialogConfig?.blurBackground}
        scrollContent={dialogConfig?.scrollContent}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useDialog = () => {
  const context = React.useContext(ConfirmationDialogContext);

  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider');
  }

  const getConfirmation = ({ ...options }) =>
    new Promise(res => {
      context.openConfirmation({ actionCallback: res, ...options });
    });

  const showAlert = ({ ...options }) =>
    new Promise(res => {
      context.openAlert({ actionCallback: res, ...options });
    });

  return { getConfirmation, showAlert, resetDialog: context.resetDialog };
};

export { DialogProvider, useDialog };
