/**
 * create a compact array from array
 *
 * @template T
 *
 * @param {Array<T | null | void>} xs
 * @returns {Array<T>}
 */
export function compactArray(xs) {
  let result = [];
  for (let i = 0; i < xs.length; ++i) {
    let elem = xs[i];
    if (elem != null) {
      result.push(elem);
    }
  }
  return result;
}
