import { ApolloProvider } from '@apollo/client';
import { CacheProvider } from '@emotion/react';
import '@fontsource/open-sans';
import { useApollo } from '@lib/api-client/createClient';
import { AuthProvider } from '@lib/authentication';
import { ConfigProvider } from '@lib/config';
import { DialogProvider } from '@lib/react-hook-dialog';
import { SnackbarProvider } from '@lib/react-hook-snackbar';
import { TrackingProvider } from '@lib/tracking';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { IntlProvider } from 'react-intl';
import createEmotionCache from 'src/createEmotionCache';
import HoneyThemeProvider from 'src/theme/HoneyThemeProvider';
import StonlyWidget from '../components/StonlyWidget';
import { getMessages, registerTranslations } from '../languages';

const apiUrl = process.env.NEXT_PUBLIC_API_URL
  ? process.env.NEXT_PUBLIC_API_URL
  : '/backbone';
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function App({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}) {
  const apolloClient = useApollo({ apiUrl });
  const { locale, defaultLocale } = useRouter();
  const messages = getMessages({ locale, defaultLocale });
  registerTranslations({ locale });

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>Trialbee Honey&trade;</title>
        </Head>
        <style jsx global>{`
          #root {
            width: 100vw;
            height: 100vh;
          }

          ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }

          ::-webkit-scrollbar-track {
            background: none !important;
          }

          ::-webkit-scrollbar-thumb {
            background: none !important;
            box-shadow: inset 0 0 10px 10px #bdbdbd;
            border: 2px solid transparent;
            border-radius: 7.5px;
            margin: 3;
          }

          ::-webkit-scrollbar-thumb:hover {
            box-shadow: inset 0 0 10px 10px #9e9e9e;
          }

          ::-webkit-scrollbar-corner {
            background: none;
          }

          /**
          * Freshdesk frame:
          * to solve overlapping with the button
          * in the right bottom corner of the learning screen
          **/
          #launcher-frame {
            bottom: 100px !important;
          }
        `}</style>
        <StonlyWidget />

        <IntlProvider
          locale={locale}
          defaultLocale={defaultLocale}
          messages={messages}
        >
          <ConfigProvider>
            <TrackingProvider>
              <ApolloProvider client={apolloClient}>
                <AuthProvider apiUrl={apiUrl}>
                  <HoneyThemeProvider>
                    <CssBaseline />
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <SnackbarProvider>
                        <DialogProvider>
                          <Component {...pageProps} />
                        </DialogProvider>
                      </SnackbarProvider>
                    </LocalizationProvider>
                  </HoneyThemeProvider>
                </AuthProvider>
              </ApolloProvider>
            </TrackingProvider>
          </ConfigProvider>
        </IntlProvider>
      </CacheProvider>
    </>
  );
}
