import { distinctArray, isEmpty, isIntersection, isNil } from '@lib/fp';

/**
 * @callback accessCallback
 * @param {Object} access
 * @param {Object} context
 * @returns {boolean}
 */

/**
 * A utility function to test access rights for a certain component. All tests in `requirements` are
 * applied using an AND algorithm. Returns a boolean by default but can also be used to do branching
 * with the `returns` parameter.
 *
 * @param {Object} requirements - The requirements to test for.
 * @param {string[]} [requirements.roles] - The roles that can pass this check. Ignored if falsy (OR)
 * @param {string[]} [requirements.actions] - The actions that can pass this check. Ignored if falsy. (OR)
 * @param {*} [requirements.context] - Custom context object to be checked against in `customCheck`
 * @param {accessCallback} [requirements.customCheck] - A custom checking function to be used in
 * more complex access checking situations.
 *
 * @returns {boolean}
 */
export function checkAccess(access = {}, requirements, features = []) {
  if (isNil(requirements)) return true;

  const candidateStatuses = distinctArray(
    access?.permissions?.flatMap(permissionToStatuses),
  );
  const accessActions = access?.actions ?? [];

  return [
    checkActions(accessActions, requirements),
    checkFeature(features, requirements),
    checkCandidateDomain(candidateStatuses, requirements),
    checkCustom(access, requirements),
  ].every(Boolean);
}

function checkActions(actions, requirements) {
  if (isEmpty(requirements.actions)) {
    return true;
  }

  return requirements.actions.some(action => actions.includes(action));
}

function checkFeature(features, requirements) {
  if (isEmpty(requirements.features)) {
    return true;
  }

  return requirements.features.some(feature => features.includes(feature));
}

function checkCustom(access, requirements) {
  if (isEmpty(requirements.customCheck)) {
    return true;
  }

  return requirements.customCheck(access, requirements.context);
}

function checkCandidateDomain(domain, requirements) {
  if (isEmpty(requirements.candidate) || domain.includes('*')) {
    return true;
  }

  return isIntersection(domain, requirements.candidate);
}

function permissionToStatuses(permission) {
  return permission?.statusesByDomain?.candidate ?? [];
}
