import { createTheme } from '@mui/material/styles';
import { cloneDeep, merge } from 'lodash';
import collapsedLogoSvg from 'public/assets/collapsed_navbar_honey_logo.svg';
import expandedLogoSvg from 'public/assets/expanded_navbar_honey_logo.svg';
import logoSvg from 'public/assets/Logo.svg';

const defaultTheme = {
  // default values except for xl
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    h1: {
      fontFamily: "'Open Sans'",
      fontWeight: 600,
      fontSize: '64px',
    },
    h2: {
      fontFamily: "'Open Sans'",
      fontWeight: 700,
      fontSize: '40px',
    },
    h3: {
      fontFamily: "'Open Sans'",
      fontWeight: 600,
      fontSize: '32px',
    },
    h4: {
      fontFamily: "'Open Sans'",
      fontWeight: 600,
      fontSize: '24px',
    },
    h5: {
      fontFamily: "'Open Sans'",
      fontWeight: 600,
      fontSize: '18px',
    },
    h6: {
      fontFamily: "'Open Sans'",
      fontWeight: 600,
      fontSize: '16px',
    },
    subtitle1: {
      fontFamily: '"Open Sans"',
      fontWeight: 600,
      fontSize: '16px',
    },
    body1: {
      fontFamily: '"Open Sans"',
      fontWeight: 400,
      fontSize: '16px',
    },
    subtitle2: {
      fontFamily: '"Open Sans"',
      fontWeight: 600,
      fontSize: '14px',
    },
    body2: {
      fontFamily: '"Open Sans"',
      fontWeight: 400,
      fontSize: '14px',
    },
    caption: {
      fontFamily: '"Open Sans"',
      fontWeight: 400,
      fontSize: '12px',
      color: '#424242',
    },
    button: {
      fontFamily: '"Open Sans"',
      fontWeight: 600,
      fontSize: '14px',
      textTransform: 'none',
    },
    overline: {
      fontFamily: '"Open Sans"',
      fontWeight: 400,
      fontSize: '12px',
      letterSpacing: '0.1em',
    },
  },
  logo: {
    fullScreenPage: logoSvg,
    navigationCollapsed: collapsedLogoSvg,
    navigationExpanded: expandedLogoSvg,
  },
  background: {
    paper: '#fff',
    default: '#fff',
    standard: '#F4F8FA',
    navigation: 'linear-gradient(180deg, #193951 55.52%, #005B86 100%)',
    fullScreenPage:
      'linear-gradient(345deg, rgba(0,91,134,1) 0%, rgba(12,145,233,1) 45%, rgba(12,145,233,1) 100%)',
  },
  palette: {
    mode: 'light',
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    text: {
      primary: '#212121',
      secondary: '#616161',
      disabled: '#9E9E9E',
    },
    primary: {
      main: '#005B86',
      light: '#006C9F',
      dark: '#00486B',
    },
    secondary: {
      main: '#0C91E9',
      light: '#1EA2FA',
      dark: '#0082D8',
    },
    error: {
      main: '#D40027',
      light: '#E5667D',
      dark: '#A9001F',
    },
    warning: {
      main: '#F3BB00',
      light: '#F7D666',
      dark: '#917000',
    },
    success: {
      main: '#00A76F',
      light: '#66CAA8',
      dark: '#008558',
    },
    info: {
      main: '#0060D1',
      light: '#669FE3',
      dark: '#004CA7',
    },
  },
  shape: {
    borderRadius: 5,
  },
};

const createDefaultComponents = ({ palette }) => ({
  MuiTextField: {
    defaultProps: { size: 'small' },
  },
  MuiButton: {
    defaultProps: { disableElevation: true },
  },
  MuiFormControl: {
    defaultProps: { size: 'small' },
  },

  MuiTabs: {
    styleOverrides: {
      indicator: {
        height: '1px',
        backgroundColor: palette.secondary.main,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: palette.grey[900],
        },
      },
    },
  },
});

export const createHoneyTheme = modifications => {
  const defaultThemeClone = cloneDeep(defaultTheme);
  const {
    components: componentModifications,
    ...nonModifications
  } = modifications;
  const mergedThemes = merge(defaultThemeClone, nonModifications);
  const defaultComponents = createDefaultComponents(mergedThemes);
  const mergedComponents = merge(defaultComponents, componentModifications);
  return createTheme(merge(mergedThemes, { components: mergedComponents }));
};
