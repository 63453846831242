import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  contentText: {
    margin: 0,
  },
  actions: {
    margin: 0,
    padding: theme.spacing(6, 3, 3, 3),
    justifyContent: 'flex-end',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: theme.palette.grey[500],
    padding: 0,
  },
  paperRoot: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 10,
  },
}));

const AlertDialog = ({
  open,
  title,
  message,
  onConfirm,
  onDismiss,
  confirmText = 'Confirm',
  scrollContent = false,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      PaperProps={{ classes: { root: classes.paperRoot } }}
    >
      <DialogTitle sx={{ typography: 'subtitle1', m: 0, p: 3 }}>
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onDismiss}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          m: 0,
          py: 0,
          px: 3,
          color: 'text.primary',
          typography: 'body1',
          overflowY: scrollContent ? 'auto' : 'initial',
          ...(scrollContent && { maxHeight: 400 }),
        }}
      >
        {message}
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Box>
          <Button color="primary" variant="outlined" onClick={onConfirm}>
            {confirmText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
