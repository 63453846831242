import { gql, useApolloClient } from '@apollo/client';
import { isNil } from '@lib/fp';
import { PROXY_NUMBER_USER_SETUP_KEY } from 'src/constants';

const QUERY = gql`
  query WEB_useAutoAssignProxyNumber {
    proxyNumbers: phoneNumbers(direction: outbound) {
      countryCode
      value
    }
  }
`;

export const useAutoAssignProxyNumber = () => {
  const client = useApolloClient();

  const autoAssignProxyNumberIfNeeded = async (
    user,
    settings,
    updateUserSettings,
  ) => {
    if (!user || !settings || !updateUserSettings) {
      console.error('useAutoAssignProxyNumberIfNeeded: missing arguments');
      return;
    }

    const currentProxyNumber =
      settings?.[PROXY_NUMBER_USER_SETUP_KEY]?.value?.number;
    const countryCode = user?.currentCountry;

    if (!isNil(currentProxyNumber) || !countryCode) {
      return;
    }

    const {
      data: { proxyNumbers },
    } = await client.query({
      query: QUERY,
      fetchPolicy: 'no-cache',
    });

    const newProxyNumber = proxyNumbers.find(
      proxyNumber =>
        proxyNumber.countryCode.toLowerCase() === countryCode.toLowerCase(),
    )?.value;

    if (newProxyNumber) {
      await updateUserSettings({
        key: PROXY_NUMBER_USER_SETUP_KEY,
        value: { number: newProxyNumber },
      });
    }
  };

  return { autoAssignProxyNumberIfNeeded };
};
