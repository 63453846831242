import collapsedLogoWinterWhiteSvg from 'public/assets/collapsed_navbar_honey_logo_winter_white.svg';
import expandedLogoWinterWhiteSvg from 'public/assets/expanded_navbar_honey_logo_winter_white.svg';
import logoWinterRedSvg from 'public/assets/Logo_winter_red.svg';
import fullScreenPageSvg from 'public/assets/winter_background_blue_stars.svg';
import { createHoneyTheme } from './createHoneyTheme';

const christmasTheme = createHoneyTheme({
  logo: {
    fullScreenPage: logoWinterRedSvg,
    navigationCollapsed: collapsedLogoWinterWhiteSvg,
    navigationExpanded: expandedLogoWinterWhiteSvg,
  },
  background: {
    standard: '#EEF5F3',
    navigation: 'linear-gradient(180deg, #5D0202 55.52%, #D10000 100%)',
    fullScreenPage: `url("${fullScreenPageSvg.src}") bottom left / cover no-repeat`,
  },
  palette: {
    secondary: {
      main: 'hsl(147, 100%, 30%)',
      light: 'hsl(147, 71%, 43%)',
      dark: 'hsl(147, 100%, 20%)',
    },
    primary: {
      main: 'hsl(147, 74%, 23%)',
      light: 'hsl(147, 60%, 37%)',
      dark: 'hsl(147, 85%, 18%)',
    },
  },
});

export default christmasTheme;
