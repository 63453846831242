import Script from 'next/script';

const StonlyWidget = () => (
  <>
    <Script id="stonly-wid" strategy="afterInteractive">
      {`window.STONLY_WID = "5329f1fb-72ef-11ec-82f1-064cee365e5a";`}
    </Script>

    <Script id="stonly-widget" strategy="afterInteractive">
      {`!function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){
      w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).scriptPath=n,w.queue=[],(y=t.createElement(o)).async=!0,
      (g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){
      4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),
      (l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())
      }(window,document,"script","https://stonly.com/js/widget/v2/");
      `}
    </Script>

    <Script id="stonly-tracker" strategy="afterInteractive">
      {`(function(s,t,o,n,l,y,_) {
        if (s.stonlyTrack) return;
        _ = s.stonlyTrack = function() {
          _._api ? _._api.apply(_, arguments) : _.queue.push(arguments);
        };
        _.queue = [];
        y = t.createElement(o);
        y.async = !0;
        y.src = n;
        l = t.getElementsByTagName(o)[0];
        l.parentNode.insertBefore(y, l);
      })(window,document,'script','https://stonly.com/js/tracker/stn.js');
      stonlyTrack('init', window.STONLY_WID);
      `}
    </Script>
  </>
);

export default StonlyWidget;
