import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

const featureOptionsQuery = gql`
  query GetFeatureOptions($studyId: ID!) {
    featureOptions(studyId: $studyId) {
      studyId
      recruitmentPortal
      consenteesPortal
      analyticsPortal
    }
  }
`;

export function useFeatures({ studyId }) {
  const { data, loading } = useQuery(featureOptionsQuery, {
    variables: { studyId },
    skip: !Boolean(studyId),
  });

  const features = useMemo(() => {
    const featureObj = {
      recruitmentPortal: data?.featureOptions?.recruitmentPortal,
      consenteesPortal: data?.featureOptions?.consenteesPortal,
      analyticsPortal: data?.featureOptions?.analyticsPortal,
    };

    return Object.entries(featureObj)
      .filter(e => e[1])
      .map(e => e[0]);
  }, [data]);

  return { features: features ?? [], loading };
}

const updateFeatureOptions = gql`
  mutation updateFeatureOptions($input: UpdateFeatureOptionsInput!) {
    updateFeatureOptions(input: $input) {
      studyId
      recruitmentPortal
      consenteesPortal
      analyticsPortal
    }
  }
`;

export const useStudyFeatureUpdate = () => {
  const [updateFeatureOptionsMutation] = useMutation(updateFeatureOptions);

  const executeFeatureUpdate = useCallback(
    input => {
      return updateFeatureOptionsMutation({ variables: { input } });
    },
    [updateFeatureOptionsMutation],
  );

  return [executeFeatureUpdate];
};
