import EmailIcon from '@mui/icons-material/Email';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import SmsIcon from '@mui/icons-material/Sms';

export const RECRUITMENT_FEATURE = 'recruitmentPortal';
export const CONSENTEE_FEATURE = 'consenteesPortal';
export const ANALYTICS_FEATURE = 'analyticsPortal';
export const FEATURES = [
  RECRUITMENT_FEATURE,
  CONSENTEE_FEATURE,
  ANALYTICS_FEATURE,
];
export const CANDIDATES_ACCORDION_KEY = '__honey_candidates_accordion_view__';
export const SUBJECT_ID = 'subject-id';

export const PROXY_NUMBER_USER_SETUP_KEY = 'userSelectedProxyNumber';

export const CONSENTEE_ROLES = ['CONSENTEE', 'ASSENTEE', 'GUARDIAN'];

export const available2FATypes = [
  {
    key: 'REQUIRED',
    name: 'Required (user must choose type)',
    default: true,
  },
  {
    key: 'FREE',
    name: 'Not required (can be used or skipped)',
  },
  {
    key: 'DISABLED',
    name: 'Disabled (not allowed for this account)',
  },
  {
    key: '---',
  },
  {
    key: 'DEVICE',
    name: 'Google authenticator',
    icon: <PhonelinkLockIcon fontSize="inherit" />,
  },
  {
    key: 'SMS',
    name: 'SMS',
    icon: <SmsIcon fontSize="inherit" />,
  },
  {
    key: 'EMAIL',
    name: 'E-mail',
    icon: <EmailIcon fontSize="inherit" />,
  },
];
